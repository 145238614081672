import { constants } from "@/constants";

export interface User {
  escId: number;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  accessToken: string;
  expires: number;
}

export class AuthService {
  public getUser(): User | null {
    const storedUser = window.localStorage.getItem(constants.LOCAL_USER_KEY);
    if (!storedUser) return null;

    return JSON.parse(storedUser);
  }

  public saveUser(user: User): void {
    window.localStorage.setItem(constants.LOCAL_USER_KEY, JSON.stringify(user));
  }

  public destroyUser(): void {
    window.localStorage.removeItem(constants.LOCAL_USER_KEY);
  }

  public getLoginUrl(backUrl: string): string {
    return process.env.VUE_APP_SSO_URL.concat(`?ReturnUrl=${backUrl}`);
  }

  public getLogoutUrl(backUrl: string): string {
    return process.env.VUE_APP_SLO_URL.concat(`?ReturnUrl=${backUrl}`);
  }

  public login(backUrl: string = window.location.href): void {
    window.location.href = this.getLoginUrl(backUrl);
  }

  public logout(backUrl: string = window.location.href): void {
    window.location.href = this.getLogoutUrl(backUrl);
  }

  public hasExpired(): boolean {
    const user = this.getUser();
    return !user || new Date().getTime() / 1000 >= user.expires - 300;
  }
}
