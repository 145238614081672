







import { Component, Vue, Watch } from "vue-property-decorator";
import ThemeSelector from "@/components/ThemeSelector.vue";
import Masonry from "@/components/Masonry.vue";
import { inject } from "inversify-props";
import { MahgApiService } from "@/services/api/magh-api";
import { PublicationSummary, ThemeDetails } from "@/services/api/magh-api-res-types";

@Component({
  components: {
    ThemeSelector,
    Masonry
  }
})
export default class HomeView extends Vue {
  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  private isLoading = true;

  private themes: Array<ThemeDetails> = [];
  private defaultTheme: ThemeDetails | null = null;
  private selectedTheme: ThemeDetails | null = null;

  private publications: Array<PublicationSummary> = [];

  @Watch("$route")
  onRouteChanged() {
    this.loadPublications();
  }

  async mounted(): Promise<void> {
    const { data: _themes } = await this._mahgApiService.getThemes();
    this.themes = _themes;

    this.defaultTheme = this.themes.find(x => x.name == "All") ?? null;
    if (this.defaultTheme == null) throw new Error("Cannot find default theme");

    this.loadPublications();
  }

  async loadPublications(): Promise<void> {
    this.isLoading = true;

    const { data: _publications } = await this._mahgApiService.getPublications({ themeId: this.defaultTheme?.id, onlyPending: this.$route.name == "Admin" });
    this.publications = _publications;

    this.isLoading = false;
  }

  async onThemeSelected(theme: ThemeDetails): Promise<void> {
    this.isLoading = true;
    this.publications = [];

    this.selectedTheme = theme;

    const { data: _publications } = await this._mahgApiService.getPublications({ themeId: theme.id });
    this.publications = _publications;

    this.isLoading = false;
  }
}
