

















































import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import VueMasonryWall from "vue-masonry-wall";
import { PublicationSummary, ThemeDetails } from "@/services/api/magh-api-res-types";
import { AuthorGetterMixin } from "@/mixins";

@Component({
  components: {
    VueMasonryWall
  }
})
export default class Mansory extends Mixins(AuthorGetterMixin) {
  @Prop({ required: true })
  readonly publications!: Array<PublicationSummary>;

  @Prop({ required: false })
  readonly theme!: ThemeDetails | null;

  private wallOptions: Record<string, any> = {
    width: 300,
    padding: {
      2: 8,
      default: 12
    }
  };
}
