






import { Component, Vue } from "vue-property-decorator";
import SubmissionForm from "@/components/SubmissionForm.vue";
import { inject } from "inversify-props";
import { MahgApiService } from "@/services/api/magh-api";
import { ThemeDetails } from "@/services/api/magh-api-res-types";

@Component({
  components: {
    SubmissionForm
  }
})
export default class PublicationView extends Vue {
  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  private isLoading = true;

  private themes: Array<ThemeDetails> = [];

  async mounted(): Promise<void> {
    const { data: _themes } = await this._mahgApiService.getThemes();
    this.themes = _themes.filter(x => x.name !== 'All');

    this.isLoading = false;
  }
}
