













import { Component, Vue } from "vue-property-decorator";
import Topbar from "@/components/Topbar.vue";
import ThemeListing from "@/components/ThemeSelector.vue";
import Masonry from "@/components/Masonry.vue";
import Profile from "@/components/Profile.vue";
import PageDetail from "@/components/PageDetail.vue";
import TabHistory from "@/components/TabHistory.vue";
import Footer from "@/components/Footer.vue";
import { MahgApiService } from "@/services/api/magh-api";
import { inject } from "inversify-props";
import { namespace } from "vuex-class";
import { AuthMutationsIdentifiers } from "./store/modules/auth-types";

const Auth = namespace("Auth");

@Component({
  components: {
    Topbar,
    ThemeListing,
    Masonry,
    Profile,
    PageDetail,
    TabHistory,
    Footer
  }
})
export default class App extends Vue {
  @Auth.State
  public loggedIn!: boolean;

  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  async mounted(): Promise<void> {
    let isAdmin = false;
    if (this.loggedIn) {
      const { data } = await this._mahgApiService.isAdmin();
      if (data.isAdmin) isAdmin = true;
    }

    this.$store.commit("Auth/" + AuthMutationsIdentifiers.SET_IS_ADMIN, isAdmin);
  }
}
