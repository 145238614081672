















































































































import { MahgApiService } from "@/services/api/magh-api";
import { ThemeDetails } from "@/services/api/magh-api-res-types";
import { inject } from "inversify-props";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ThemeSelector extends Vue {
  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  @Prop({ required: true })
  readonly themes!: Array<ThemeDetails>;

  @Prop({ required: true })
  readonly defaultThemeId!: number;

  private selectedThemeId = this.defaultThemeId;

  get themesLgSummary(): Array<ThemeDetails> {
    let beforeEl: Array<ThemeDetails> = [];
    let afterEl: Array<ThemeDetails> = [];

    const targetEl = this.themes.find(x => x.id == this.selectedThemeId);
    if (targetEl == null) throw new Error("Unable to find selected theme");

    const targetIndex = this.themes.indexOf(targetEl);

    const beforeElStart = targetIndex;
    const beforeElEnd = beforeElStart - 4;
    const isBeforeElEndNeg = beforeElEnd < 0;

    if (!isBeforeElEndNeg) {
      beforeEl = this.themes.slice(beforeElEnd, beforeElStart);
    } else {
      const _beforeEl = this.themes.slice(0, beforeElStart);
      beforeEl = this.themes.slice(-Math.abs(4 - _beforeEl.length));
      beforeEl = beforeEl.concat(_beforeEl);
    }

    const afterElStart = targetIndex + 1;
    const afterElEnd = afterElStart + 4;
    const isAfterElOverflow = afterElEnd > this.themes.length - 1;
    if (!isAfterElOverflow) {
      afterEl = this.themes.slice(afterElStart, afterElEnd);
    } else {
      afterEl = this.themes.slice(afterElStart, this.themes.length);
      afterEl = afterEl.concat(this.themes.slice(0, 4 - afterEl.length));
    }

    const themesLgSummary = beforeEl.concat(targetEl).concat(afterEl);
    return themesLgSummary;
  }

  get themesSmSummary(): Array<ThemeDetails> {
    const targetIndex = this.themes.findIndex(x => x.id == this.selectedThemeId);

    const beforeEl = this.themes.slice(0, targetIndex);
    const afterEl = this.themes.slice(targetIndex, this.themes.length);

    const themesSmSummary = afterEl.concat(beforeEl);
    return themesSmSummary;
  }

  get selectedTheme() {
    return this.themes.find(x => x.id == this.selectedThemeId);
  }

  mounted(): void {
    // If needed...
  }

  selectTheme(themeId: number): void {
    if (this.selectedThemeId !== themeId) {
      this.selectedThemeId = themeId;
      this.$emit("theme-selected", this.selectedTheme);

      this.$root.$emit('bv::hide::modal', 'modal-theme-lg');
      this.$root.$emit('bv::hide::modal', 'modal-theme-sm');
    }
  }
}
