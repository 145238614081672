











































import { AuthService } from "@/services/auth";
import { inject } from "inversify-props";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");

@Component
export default class Topbar extends Vue {
  @Auth.State
  public loggedIn!: boolean;

  @Auth.Getter
  public firstName!: string;

  @Auth.Getter
  public fullName!: string;

  private loginUrl = "#";
  private logoutUrl = "#";

  @inject("AuthService")
  private readonly _authService!: AuthService;

  get returnUrl(): string {
    return window.location.origin + this.$route.fullPath;
  }

  mounted(): void {
    this.loginUrl = this._authService.getLoginUrl(this.returnUrl);
    this.logoutUrl = this._authService.getLogoutUrl(this.returnUrl);
  }
}
