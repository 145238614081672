import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { Store } from "vuex";
import { buildAuthModule } from "@/store/modules/auth";

Vue.use(Vuex);

export function buildStore(): Store<any> {
  const initialState: any = {};

  const store: StoreOptions<any> = {
    state: initialState,
    modules: {
      Auth: buildAuthModule()
    }
  };

  return new Store<any>(store);
}
