import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { Plugin } from "vue-fragment";
import VueRouter from "vue-router";
import { Store } from "vuex";

import VueTagManager from "vue-tag-manager";
// import { ApplicationInsights } from "@microsoft/applicationinsights-web";
// import VueAppInsights from "vue-application-insights";
import FileSelector from "vue-file-selector";

import { container } from "inversify-props";
import registerServices from "./kernel";
import { buildRouter } from "./router";
import { buildStore } from "./store";

import App from "./App.vue";

import "@/filters";

import "../node_modules/line-awesome/dist/line-awesome/css/line-awesome.min.css";
import "./styles/app.scss";

Vue.config.productionTip = false;

// BootstrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// VueFragment
Vue.use(Plugin);

// VueTagManager
Vue.use(VueTagManager, {
  gtmId: process.env.VUE_APP_GTM_ID
});

// VueFileSelector
Vue.use(FileSelector);

let router: VueRouter;
let store: Store<any>;

class AppWrapper {
  constructor() {
    registerServices().then(() => {
      console.log("Services registered");
      console.debug("Container", container);
      router = buildRouter();
      store = buildStore();
      this.start();
    });
  }

  private async start(): Promise<Vue> {
    return new Vue({
      router: router,
      store: store,
      render: h => h(App)
    }).$mount("#app");
  }
}

new AppWrapper();

export { store, router };
