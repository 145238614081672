import { constants } from "@/constants";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PublicationView from "../views/PublicationView.vue";
import ProfileView from "../views/ProfileView.vue";
import SubmitView from "../views/SubmitView.vue";
import { store } from "@/main";
import { AuthService } from "@/services/auth";
import { container } from "inversify-props";
import { AuthActionsIdentifiers } from "@/store/modules/auth-types";
import { getBaseUrl } from "@/helpers";

Vue.use(VueRouter);

export function buildRouter(): VueRouter {
  const authService = container.get<AuthService>("AuthService");

  const routes: Array<RouteConfig> = [
    {
      path: "/",
      alias: constants.ROUTE_HOME,
      name: "Home",
      component: HomeView
    },
    {
      path: "/admin",
      name: "Admin",
      component: HomeView
    },
    {
      path: "/publications/:publicationFurl",
      props: true,
      name: "Publication",
      component: PublicationView
    },
    {
      path: "/submit",
      name: "Submit",
      component: SubmitView
    },
    {
      path: "/authors/:authorFurl",
      props: true,
      name: "Profile",
      component: ProfileView
    }
  ];

  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
  });

  router.beforeEach(async (to, from, next) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("token")) {
      // If a token is in query string, dispatch login action
      await store.dispatch("Auth/" + AuthActionsIdentifiers.LOGIN, urlParams);
    }

    const expired = authService.hasExpired();
    if (expired) await store.dispatch("Auth/" + AuthActionsIdentifiers.LOGOUT);

    const targetRoute = to.name ?? "";
    const restrictedRoutes = ["Submit", "Admin"];

    const loggedIn = store.state.Auth.loggedIn;

    if (restrictedRoutes.includes(targetRoute) && !loggedIn) {
      const backUrl = `${getBaseUrl()}${to.fullPath}`;
      authService.login(backUrl);
      return;
    }

    return next();
  });

  router.afterEach(to => {
    const query = to.query;
    if (query.token || query.hpa) {
      const nextQuery = Object.assign({}, query);
      delete nextQuery.token;
      delete nextQuery.expires;
      delete nextQuery.escId;
      delete nextQuery.email;
      delete nextQuery.title;
      delete nextQuery.title;
      delete nextQuery.firstName;
      delete nextQuery.lastName;

      return router.replace({
        path: to.path,
        query: nextQuery
      });
    }
  });

  return router;
}
