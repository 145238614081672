import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

@Component
class RefreshPageMixin extends Vue {
  private currentHash = "{{POST_BUILD_ENTERS_HASH_HERE}}";
  private newVersion = false;
  private newHash = "";

  get checkVersionFile(): string {
    return process.env.VUE_APP_CHECK_VERSION_FILE;
  }

  public initVersionCheck(frequency: number): void {
    setInterval(() => {
      this.checkVersion();
    }, frequency);
  }

  public async checkVersion(): Promise<void> {
    try {
      const fileResponse = await axios
        .create({
          baseURL: `${window.location.origin}`,
          headers: {
            "Content-type": "application/json"
          }
        })
        .get("/" + this.checkVersionFile + "?t=" + new Date().getTime());

      this.newHash = fileResponse.data.hash;

      this.newVersion = this.hasHashChanged(this.currentHash, this.newHash);
      if (this.newVersion) console.debug(`NewVersion: ${JSON.stringify({ currentHash: this.currentHash, newHash: this.newHash })}`);
    } catch (error) {
      if (!error.response) {
        console.error("Error: Network Error");
      } else {
        console.error(error.response.data.message);
      }
    }
  }

  public hasHashChanged(currentHash: string, newHash: string): boolean {
    if (!currentHash || currentHash === "{{POST_BUILD_ENTERS_HASH_HERE}}") {
      return true;
    }

    return currentHash !== newHash;
  }

  public reloadApp(): void {
    this.currentHash = this.newHash;
    window.location.reload();
  }
}

export { RefreshPageMixin };
