// import * as rax from "retry-axios";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { inject } from "inversify-props";
import { constants } from "@/constants";
import { AuthService } from "@/services/auth";
import { store } from "@/main";
import { AuthorDetails, IsAdminResponse, LikesAlterPublicationResult, PublicationDetails, PublicationSummary, ThemeDetails } from "./magh-api-res-types";
import { AuthActionsIdentifiers } from "@/store/modules/auth-types";
import { AlterPublicationResource, PublicationsListResource, SubmitPublicationsResource } from "./magh-api-req-types";

export class MahgApiService {
  @inject("AuthService")
  private readonly _authService!: AuthService;

  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_MAHG_API_BASE_URL
    });

    this.axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        if (error.response) {
          const status = error.response?.status;
          const message = error.response?.data?.message;

          if (status == 401 && message == "Unauthorized") {
            const backUrl = window.location.href;
            console.warn("Force relogin due to unauthorized api call");

            await store.dispatch("Auth/" + AuthActionsIdentifiers.LOGOUT);
            this._authService.login(backUrl);
          }
        }
        throw error;
      }
    );
  }

  public setHeader(): void {
    const user = this._authService.getUser();
    if (user) this.axiosInstance.defaults.headers.common["Authorization"] = `Token ${user.accessToken}`;
  }

  //#region Account

  public isAdmin(): Promise<AxiosResponse<IsAdminResponse>> {
    return this.axiosInstance.get(constants.MAHG_API_ACCOUNT_IS_ADMIN_ENDPOINT);
  }

  ////#endregion

  //#region Publications

  public getPublications(publicationListReq: PublicationsListResource): Promise<AxiosResponse<Array<PublicationSummary>>> {
    return this.axiosInstance.post<Array<PublicationSummary>>(constants.MAHG_API_PUBLICATIONS_LIST_ENDPOINT, publicationListReq);
  }

  public getPublicationDetails(publicationId: number): Promise<AxiosResponse<PublicationDetails>> {
    return this.axiosInstance.get<PublicationDetails>(constants.MAHG_API_PUBLICATION_DETAILS_ENDPOINT.replace("{PublicationId}", publicationId.toString()));
  }

  public submitPublication(submitPublicationReq: SubmitPublicationsResource): Promise<AxiosResponse> {
    return this.axiosInstance.post(constants.MAHG_API_SUBMIT_PUBLICATION_ENDPOINT, submitPublicationReq);
  }

  public alterPublication(alterPublicationReq: AlterPublicationResource): Promise<AxiosResponse> {
    return this.axiosInstance.post(constants.MAHG_API_ALTER_PUBLICATION_ENDPOINT, alterPublicationReq);
  }

  //#endregion

  //#region Likes

  public likeOrUnlikePublication(publicationId: number): Promise<AxiosResponse<LikesAlterPublicationResult>> {
    return this.axiosInstance.post<LikesAlterPublicationResult>(constants.MAHG_API_ALTER_PUBLICATION_LIKE_ENDPOINT, {
      PublicationId: publicationId
    });
  }

  //#endregion

  //#region Themes

  public getThemes(): Promise<AxiosResponse<Array<ThemeDetails>>> {
    return this.axiosInstance.get<Array<ThemeDetails>>(constants.MAHG_API_THEMES_LIST_ENDPOINT);
  }

  //#endregion

  //#region Authors

  public getAuthorDetails(authorId: number): Promise<AxiosResponse<AuthorDetails>> {
    return this.axiosInstance.get<AuthorDetails>(constants.MAHG_API_AUTHOR_DETAILS_ENDPOINT.replace("{AuthorId}", authorId.toString()));
  }

  //#endregion
}
