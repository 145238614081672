
























































































































import { AuthorGetterMixin } from "@/mixins";
import { MahgApiService } from "@/services/api/magh-api";
import { PublicationDetails } from "@/services/api/magh-api-res-types";
import { inject } from "inversify-props";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");

@Component
export default class PageDetail extends Mixins(AuthorGetterMixin) {
  @Auth.State
  public isAdmin!: boolean;

  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  @Prop({ required: true })
  private readonly publication!: PublicationDetails;

  private likesCount = 0;
  private isLiked = false;

  private isLikeLoading = false;

  get hasHistory(): boolean {
    return window.history.length > 1;
  }

  get statusBadgeVariant(): string {
    if (this.publication.status == "Approved") return "success";
    else if (this.publication.status == "Pending") return "warning";
    else if (this.publication.status == "Rejected") return "danger";
    else return "secondary";
  }

  mounted(): void {
    this.likesCount = this.publication.likesCount;
    this.isLiked = this.publication.isLiked;
  }

  onBackClick(): void {
    if (this.hasHistory) this.$router.go(-1);
    else this.$router.push("/");
  }

  onLikeBtnClick(): void {
    this.isLikeLoading = true;

    this._mahgApiService.likeOrUnlikePublication(this.publication.id).then(res => {
      this.isLiked = res.data?.isLiked;

      if (this.isLiked) this.likesCount += 1;
      else this.likesCount -= 1;

      this.isLikeLoading = false;
    });
  }

  onApproveClick(): void {
    this._mahgApiService
      .alterPublication({ publicationId: this.publication.id, publicationStatus: "Approved" })
      .then(({ data }) => {
        this.publication.status = data.status ?? "Unknown";
      })
      .catch(() => {
        this.publication.status = "Unknown";
      });
  }

  onRejectClick(): void {
    this._mahgApiService
      .alterPublication({ publicationId: this.publication.id, publicationStatus: "Rejected" })
      .then(({ data }) => {
        this.publication.status = data.status ?? "Unknown";
      })
      .catch(() => {
        this.publication.status = "Unknown";
      });
  }
}
