

























































































import { MahgApiService } from "@/services/api/magh-api";
import { SubmitPublicationsResourceAttachment } from "@/services/api/magh-api-req-types";
import { ThemeDetails } from "@/services/api/magh-api-res-types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { inject } from "inversify-props";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

@Component({
  mixins: [validationMixin],
  validations: {
    title: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(65)
    },
    description: {
      minLength: minLength(12),
      maxLength: maxLength(255)
    },
    themeId: {
      required
    }
  }
})
export default class SubmissionForm extends Vue {
  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  @Prop({ required: true })
  readonly themes!: Array<ThemeDetails>;

  private showForm = true;
  private isFileSelectorLoading = false;

  private isLoading = false;
  private postStatus = "Draft";

  private title = "";
  private description = "";
  private themeId: number | null = null;
  private files: Array<any> | null = null;

  mounted(): void {
    // If needed...
  }

  validateState(input: string): any {
    const vState = this.$v[input];
    return vState?.$dirty ? !vState.$error : null;
  }

  validateFiles(): any {
    if (!this.files) return null;
    if (this.files.length == 0) return false;
    return true;
  }

  onSubmit(event: any): void {
    event.preventDefault();

    this.touchImages();
    if ((this.files as Array<any>)?.length == 0) return;

    this.$v.$touch();
    if (this.$v.$invalid) return;

    const attachments = new Array<SubmitPublicationsResourceAttachment>();
    for (const file of this.files as Array<any>) {
      const src: string = file.src;

      const mimeType = src.split("data:")[1].split(";")[0];
      const base64 = src.split("base64,")[1];

      if (!mimeType || !base64) continue;

      attachments.push({
        mimeType,
        base64
      });
    }

    this.isLoading = true;

    this._mahgApiService
      .submitPublication({
        title: this.title,
        description: this.description.trim() !== "" ? this.description : null,
        themeId: this.themeId as number,
        attachments: attachments
      })
      .then(res => {
        if (res?.status == 200) this.postStatus = "Published";
        else this.postStatus = "Errored";
      })
      .catch(err => {
        this.postStatus = "Errored";
        console.error("An error has occurred", err);
      })
      .finally(() => {
        window.scrollTo(0, 0);
        this.isLoading = false;
      });
  }

  onReset(event: any): void {
    event.preventDefault();

    this.title = "";
    this.description = "";
    this.themeId = null;
    this.files = [];

    // Trick to reset/clear native browser form validation state
    this.showForm = false;

    this.$nextTick(() => {
      this.showForm = true;
    });
  }

  touchImages(): void {
    if (!this.files) this.files = [];
  }

  onFileClick(index: number): void {
    (this.files as Array<any>).splice(index, 1);
  }

  handleFilesValidated(valid: boolean, files: Array<any>): void {
    // If needed...
  }

  async handleFilesChanged(files: Array<any>): Promise<void> {
    this.isFileSelectorLoading = true;
    this.touchImages();

    const list = Array.from(files);
    for (const file of list) {
      const imgSrc = await this.loadImgAsDataUrl(file);
      (this.files as Array<any>).push({
        name: file.name,
        size: file.size,
        src: imgSrc
      });
    }

    this.isFileSelectorLoading = false;
  }

  async loadImgAsDataUrl(file: Blob): Promise<string | ArrayBuffer> {
    const url = await new Promise<string | ArrayBuffer>(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => resolve(e?.target?.result ?? "");
    });
    return url;
  }

  formatNumber(num: number): string {
    return new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  }
}
