







import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import Profile from "@/components/Profile.vue";
import Masonry from "@/components/Masonry.vue";
import { inject } from "inversify-props";
import { MahgApiService } from "@/services/api/magh-api";
import { AuthorDetails, PublicationSummary } from "@/services/api/magh-api-res-types";
import { extractEntityId } from "@/helpers";
import { AuthorGetterMixin } from "@/mixins"

@Component({
  components: {
    Profile,
    Masonry
  }
})
export default class ProfileView extends Vue {
  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  @Prop({ required: true })
  private readonly authorFurl!: string;

  private isLoading = true;

  private profile: AuthorDetails | null = null;
  private publications: Array<PublicationSummary> = [];

  async mounted(): Promise<void> {
    const authorId = extractEntityId(this.authorFurl);
    if (!authorId || authorId <= 0) throw new Error("Unable to extract author id");

    const { data: _profile } = await this._mahgApiService.getAuthorDetails(authorId);
    this.profile = _profile;

    const { data: _publications } = await this._mahgApiService.getPublications({ authorId: authorId });
    this.publications = _publications;

    this.isLoading = false;
  }
}
