








































import { AuthorGetterMixin } from "@/mixins";
import { AuthorDetails } from "@/services/api/magh-api-res-types";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class Profile extends Mixins(AuthorGetterMixin) {
  @Prop({ required: true })
  private readonly profile!: AuthorDetails;
}
