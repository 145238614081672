export interface AuthActions {
  LOGIN: string;
  LOGOUT: string;
  CHECK_AUTH: string;
}

export const AuthActionsIdentifiers: AuthActions = {
  LOGIN: "login",
  LOGOUT: "logout",
  CHECK_AUTH: "checkAuth"
};

export interface AuthMutations {
  SET_AUTH: string;
  PURGE_AUTH: string;
  SET_IS_ADMIN: string;
}

export const AuthMutationsIdentifiers: AuthMutations = {
  SET_AUTH: "setAuth",
  PURGE_AUTH: "purgeAuth",
  SET_IS_ADMIN: "setIsAdmin"
};
