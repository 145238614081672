export interface Constants {
  LOCAL_USER_KEY: string;

  MAHG_API_PUBLICATIONS_LIST_ENDPOINT: string;
  MAHG_API_PUBLICATION_DETAILS_ENDPOINT: string;
  MAHG_API_SUBMIT_PUBLICATION_ENDPOINT: string;
  MAHG_API_ALTER_PUBLICATION_ENDPOINT: string;
  MAHG_API_ALTER_PUBLICATION_LIKE_ENDPOINT: string;
  MAHG_API_THEMES_LIST_ENDPOINT: string;
  MAHG_API_AUTHOR_DETAILS_ENDPOINT: string;
  MAHG_API_ACCOUNT_IS_ADMIN_ENDPOINT: string;

  ROUTE_HOME: string;
  ROUTE_PUBLICATION_DETAILS: string;
}

export const constants: Constants = {
  // LocalStorage
  LOCAL_USER_KEY: "user",

  // MediaAPI
  MAHG_API_PUBLICATIONS_LIST_ENDPOINT: "Publications/List",
  MAHG_API_PUBLICATION_DETAILS_ENDPOINT: "Publications/{PublicationId}",
  MAHG_API_SUBMIT_PUBLICATION_ENDPOINT: "Publications/Submit",
  MAHG_API_ALTER_PUBLICATION_ENDPOINT: "Publications/Alter",

  MAHG_API_ALTER_PUBLICATION_LIKE_ENDPOINT: "Likes/Alter",

  MAHG_API_THEMES_LIST_ENDPOINT: "Themes/List",

  MAHG_API_AUTHOR_DETAILS_ENDPOINT: "Authors/{AuthorId}",

  MAHG_API_ACCOUNT_IS_ADMIN_ENDPOINT: "Account/IsAdmin",

  // Named Routes
  ROUTE_HOME: "home",
  ROUTE_PUBLICATION_DETAILS: "publications/:publicationId"
};
