






import { Component, Prop, Vue } from "vue-property-decorator";
import PageDetail from "@/components/PageDetail.vue";
import { inject } from "inversify-props";
import { MahgApiService } from "@/services/api/magh-api";
import { PublicationDetails } from "@/services/api/magh-api-res-types";
import { extractEntityId } from "@/helpers";

@Component({
  components: {
    PageDetail
  }
})
export default class PublicationView extends Vue {
  @inject("MahgApiService")
  private readonly _mahgApiService!: MahgApiService;

  @Prop({ required: true })
  private readonly publicationFurl!: string;

  private isLoading = true;

  private publication: PublicationDetails | null = null;

  mounted(): void {
    const publicationId = extractEntityId(this.publicationFurl);
    if (!publicationId || publicationId <= 0) throw new Error("Unable to extract publication id");

    this._mahgApiService.getPublicationDetails(publicationId).then(res => {
      this.publication = res.data;
      this.isLoading = false;
    });
  }
}
