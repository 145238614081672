import "reflect-metadata";
import { container } from "inversify-props";
import { AuthService } from "@/services/auth";
import { MahgApiService } from "@/services/api/magh-api";

export default async function registerServices(): Promise<void> {
  return new Promise(resolve => {
    container.addSingleton(AuthService, "AuthService");
    container.addSingleton(MahgApiService, "MahgApiService");
    resolve();
  });
}
