import { AuthService, User } from "@/services/auth";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { cid, container } from "inversify-props";
import { AuthActionsIdentifiers, AuthMutationsIdentifiers } from "./auth-types";
import { MahgApiService } from "@/services/api/magh-api";

export interface AuthState {
  loggedIn: boolean;
  user: User | null;
  isAdmin: boolean;
}

export function buildAuthModule(): Module<AuthState, any> {
  const authService = container.get<AuthService>("AuthService");
  const mahgApiService = container.get<MahgApiService>("MahgApiService");

  const user = authService.getUser() ?? null;
  if (user) mahgApiService.setHeader();

  const initialState: AuthState = {
    loggedIn: user !== null,
    user: user,
    isAdmin: false
  };

  const getters: GetterTree<AuthState, any> = {
    fullName(state): string {
      return `${state.user?.firstName} ${state.user?.lastName}`;
    },
    firstName(state): string {
      return `${state.user?.firstName}`;
    }
  };

  const actions: ActionTree<AuthState, any> = {
    [AuthActionsIdentifiers.LOGIN]({ commit }, urlParams: URLSearchParams): void {
      const _escId = urlParams.get("escId");
      const _email = urlParams.get("email");
      const _title = urlParams.get("title");
      const _firstName = urlParams.get("firstName");
      const _lastName = urlParams.get("lastName");
      const _token = urlParams.get("token");
      const _expires = urlParams.get("expires");

      if (!_escId || !_email || !_title || !_firstName || !_lastName || !_token || !_expires) return;

      const escId = parseInt(window.atob(_escId));
      const email = window.atob(_email);
      const title = window.atob(_title);
      const firstName = window.atob(_firstName);
      const lastName = window.atob(_lastName);
      const expires = parseInt(_expires);

      if (!escId || !email || !title || !firstName || !lastName || !expires) return;

      const user: User = {
        escId,
        email,
        title,
        firstName,
        lastName,
        accessToken: _token,
        expires
      };

      authService.saveUser(user);
      mahgApiService.setHeader();

      commit(AuthMutationsIdentifiers.SET_AUTH, user);
    },
    [AuthActionsIdentifiers.LOGOUT]({ commit }): void {
      authService.destroyUser();
      commit(AuthMutationsIdentifiers.PURGE_AUTH);
    }
  };

  const mutations: MutationTree<AuthState> = {
    [AuthMutationsIdentifiers.SET_AUTH](state, user: User): void {
      state.loggedIn = true;
      state.user = user;
    },
    [AuthMutationsIdentifiers.PURGE_AUTH](state): void {
      state.loggedIn = false;
      state.user = null;
    },
    [AuthMutationsIdentifiers.SET_IS_ADMIN](state, isAdmin: boolean): void {
      state.isAdmin = isAdmin;
    }
  };

  const Auth: Module<AuthState, any> = {
    namespaced: true,
    state: initialState,
    getters: getters,
    actions,
    mutations
  };

  return Auth;
}
