import { Component, Vue } from "vue-property-decorator";
import DefaultAvatar from "@/assets/images/default-avatar.png";
import { AuthorDetails, PublicationDetails } from "@/services/api/magh-api-res-types";

@Component
class AuthorGetterMixin extends Vue {
  getAuthorPictureUrlByPublication(publication: PublicationDetails): string {
    return publication?.authorPictureUrl ? publication.authorPictureUrl : DefaultAvatar;
  }
  
  getAuthorPictureUrlByProfile(profile: AuthorDetails): string {
    return profile?.pictureUrl ? profile.pictureUrl : DefaultAvatar;
  }
}

export { AuthorGetterMixin };
